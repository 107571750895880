
import EditAboutDialog from "@/components/ui/Modal/Dialog/instances/EditAboutDialog.vue";
import EditAboutContactsDialog from "@/components/ui/Modal/Dialog/instances/EditAboutContactsDialog.vue";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/runtime-core";
import AboutProductTable from "@/components/Table/ts/instances/about-product/AboutProductTable.vue";
import { useStore } from "vuex";
import {
  optionsAbouts,
  useAboutProduct
} from "@/hooks/about-product/useAboutProduct";
import { sortOrders } from "@/enums/main/sortOrders";
import useAboutLocalizations from "@/hooks/about-product/useAboutLocalizations";
import { IMappedAboutLocalization } from "@/api/services/about-products/aboutTypes";

const tabs = [
  {
    id: 1,
    title: "About",
    value: "about"
  },
  {
    id: 2,
    title: "Legal",
    value: "legal"
  },
  {
    id: 3,
    title: "Contacts",
    value: "about-app-contacts"
  }
];

interface editData {
  id: number;
  title: string;
  type?: string;
  description: string;
}

export default defineComponent({
  name: "Questions",
  components: {
    EditAboutDialog,
    AboutProductTable,
    EditAboutContactsDialog
  },
  setup() {
    const store = useStore();
    const selectedTab: optionsAbouts = reactive({
      id: tabs[0].id,
      title: tabs[0].title,
      value: tabs[0].value
    });

    const options = {
      selectedTab
    };

    const {
      localizations,
      localizationList,
      startEditing,
      saveLocalization
    } = useAboutLocalizations(options);

    const { requestAbouts, tabToSelect, editAbouts } = useAboutProduct(options);

    requestAbouts();

    watch(selectedTab, requestAbouts, {
      immediate: true,
      deep: true
    });

    let selectMenu = ref(tabToSelect(tabs));

    return {
      selectMenu,
      selectedTab,
      editAbouts,
      aboutsList: computed(() => store.getters.aboutsList),
      aboutsTotal: computed(() => store.getters.aboutsTotal),
      localizations,
      localizationList,
      startEditing,
      requestAbouts,
      saveLocalization,
      changeTabHandler(tab: optionsAbouts): void {
        selectedTab.id = tab.id;
        selectedTab.value = tab.value;
        selectedTab.title = tab.title;
      }
    };
  },

  data() {
    return {
      tabs,
      questions: {},
      hideButton: true,
      aboutType: false,
      loadingCreate: false,
      editData: {
        title: "",
        id: 0,
        type: "",
        description: ""
      } as editData | undefined,
      editModalOpened: false,
      editModalContactOpened: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" },
        byType: { order: sortOrders.asc, keyName: "type" }
      }
    };
  },

  computed: {
    editModalTitle() {
      return "Edit";
    }
  },
  methods: {
    async editAboutSubmit(data: editData) {
      this.loadingCreate = true;
      const success = await this.editAbouts(data);
      this.loadingCreate = false;
      if (success) {
        this.editModalContactOpened = false;
        this.editModalOpened = false;
        this.requestAbouts();
      }
    },
    async editAboutModal(id: number) {
      this.selectedTab.value !== "about-app-contacts" && this.startEditing(id);
      this.editData = this.aboutsList.find((about: any) => about.id === id);
      if (this.editData?.type) {
        this.editModalContactOpened = true;
      } else {
        this.editModalOpened = true;
      }
    },
    async saveLocalizationHandler(localization: IMappedAboutLocalization) {
      this.loadingCreate = true;
      const success = await this.saveLocalization(localization);
      if (success) {
        this.editModalContactOpened = false;
        this.editModalOpened = false;
      }
      this.loadingCreate = false;
    },
    hideModal() {
      this.editModalOpened = false;
      this.editModalContactOpened = false;
    }
  }
});
