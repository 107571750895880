import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 12 }
const _hoisted_2 = { class: "pt-3" }
const _hoisted_3 = { class: "mt-3 pb-4" }
const _hoisted_4 = { class: "pt-3" }
const _hoisted_5 = { class: "mt-3 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TabsDialog = _resolveComponent("TabsDialog")!

  return (_openBlock(), _createBlock(_component_TabsDialog, {
    key: 1,
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedTab) = $event)),
    tabs: _ctx.tabs,
    opened: _ctx.opened,
    title: _ctx.editingData?.title || _ctx.editingData?.type,
    class: "create-location-modal",
    onHide: _ctx.hide
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppInput, {
              id: "question-title",
              modelValue: _ctx.editData.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editData.title) = $event)),
              "input-type": "material",
              label: "Title",
              type: "text",
              name: "question-title"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppInput, {
                id: "category-description",
                modelValue: _ctx.editData.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editData.description) = $event)),
                label: "Description",
                "input-type": "material",
                "is-textarea": true,
                style: {"height":"215px"}
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode(_component_AppButton, {
            loading: _ctx.loading,
            disabled: !_ctx.valid,
            type: "secondary",
            size: "xl",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Edit ")
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ], 32)
      ])),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localizations, (localization) => {
        return (_openBlock(), _createElementBlock("div", {
          key: localization.localizationId
        }, [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppInput, {
                id: "question-title",
                modelValue: localization.title,
                "onUpdate:modelValue": ($event: any) => ((localization.title) = $event),
                "input-type": "material",
                label: "Title",
                type: "text",
                name: "question-title"
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_AppInput, {
                  id: "category-description",
                  modelValue: localization.description,
                  "onUpdate:modelValue": ($event: any) => ((localization.description) = $event),
                  label: "Description",
                  "input-type": "material",
                  "is-textarea": true,
                  style: {"height":"215px"}
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]),
            _createVNode(_component_AppButton, {
              loading: _ctx.loading,
              disabled: 
            !(localization?.title !== '' && localization?.description !== '')
          ,
              type: "secondary",
              size: "xl",
              onClick: ($event: any) => (_ctx.saveLocalization(localization))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Save localization ")
              ]),
              _: 2
            }, 1032, ["loading", "disabled", "onClick"])
          ], 32)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "tabs", "opened", "title", "onHide"]))
}