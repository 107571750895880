import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditAboutDialog = _resolveComponent("EditAboutDialog")!
  const _component_EditAboutContactsDialog = _resolveComponent("EditAboutContactsDialog")!
  const _component_AboutProductTable = _resolveComponent("AboutProductTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EditAboutDialog, {
      "editing-data": _ctx.editData?.id ? _ctx.editData : null,
      opened: _ctx.editModalOpened,
      title: _ctx.editModalTitle,
      loading: _ctx.loadingCreate,
      "localizations-list": _ctx.localizationList,
      "selected-parent-tab": _ctx.selectedTab,
      onSubmit: _ctx.editAboutSubmit,
      onSaveLocalization: _ctx.saveLocalizationHandler,
      onHide: _ctx.hideModal
    }, null, 8, ["editing-data", "opened", "title", "loading", "localizations-list", "selected-parent-tab", "onSubmit", "onSaveLocalization", "onHide"]),
    _createVNode(_component_EditAboutContactsDialog, {
      "editing-data": _ctx.editData?.id ? _ctx.editData : null,
      opened: _ctx.editModalContactOpened,
      title: _ctx.editModalTitle,
      loading: _ctx.loadingCreate,
      "modal-type": _ctx.aboutType,
      onSubmit: _ctx.editAboutSubmit,
      onHide: _ctx.hideModal
    }, null, 8, ["editing-data", "opened", "title", "loading", "modal-type", "onSubmit", "onHide"]),
    _createVNode(_component_AboutProductTable, {
      sort: _ctx.sortConfig,
      "tabs-list": _ctx.tabs,
      "about-list": _ctx.aboutsList,
      "total-rows-count": _ctx.aboutsTotal,
      "hide-button": _ctx.hideButton,
      "table-title": 'About Products',
      onChangeTab: _ctx.changeTabHandler,
      onEditDeviceAction: _ctx.editAboutModal
    }, null, 8, ["sort", "tabs-list", "about-list", "total-rows-count", "hide-button", "onChangeTab", "onEditDeviceAction"])
  ], 64))
}