import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "account-wrapper",
  style: {"max-width":"100%","overflow":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbarTable = _resolveComponent("AppToolbarTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppToolbarTable, {
      "pagination-capture-key": "About product",
      "table-title": _ctx.tableTitle,
      "table-head-tabs": _ctx.tabsList,
      "table-body": _ctx.rows,
      "total-rows": _ctx.totalRowsCount,
      "hide-button": _ctx.hideButton,
      "table-head-table": _ctx.tableHeadTable,
      onChangeTab: _ctx.changeTab,
      onSort: _ctx.changeSorting,
      onOpenForm: _ctx.openForm,
      onEditItem: _ctx.editDeviceAction,
      onRemoveItem: _ctx.deleteDeviceAction
    }, null, 8, ["table-title", "table-head-tabs", "table-body", "total-rows", "hide-button", "table-head-table", "onChangeTab", "onSort", "onOpenForm", "onEditItem", "onRemoveItem"])
  ]))
}