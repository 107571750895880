
import { Router, useRouter } from "vue-router";
import { ComputedRef, defineComponent, PropType, computed, watch } from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { userId } from "@/store/interfaces/auth/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { dateConverter } from "@/utills/dateConverter";

type tableHeadCells = ITableHeadCell[];

export type operatorsTableSort = {
  byName: sortItem;
  byDateCreated: sortItem;
};

export default defineComponent({
  name: "AccountsTable",
  components: { AppTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    // base
    const rowDetailsBaseUrl = "/user";
    const rowDetailsQueryKey = "userId";

    const router: Router = useRouter();

    const initialSortOptions: operatorsTableSort = {
      byName: {
        order: sortOrders.asc,
        keyName: "fullName",
        keyNameInModel: "fullName"
      },
      byDateCreated: {
        order: sortOrders.turnOff,
        keyName: "deteCreated",
        keyNameInModel: "createdAt"
      }
    };

    // row actions
    const userEditHandler = (id: userId) => rowClickHandler(id);
    const userDeleteHandler = (id: userId) => emit("delete", id);

    async function rowClickHandler(id: userId): Promise<void> {
      await router.push({
        path: rowDetailsBaseUrl,
        query: {
          [rowDetailsQueryKey]: id
        }
      });
    }

    // table model
    const operatorsList: ComputedRef<IUserAccount[]> = computed(
      () => props.accountsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },
      initialSortOptions,
      model: operatorsList,
      generateRows(operatorsList: IUserAccount[]): ITableBodyRow[] {
        return operatorsList.map(
          (account: IUserAccount): ITableBodyRow => {
            const {
              id,
              fullName,
              email,
              image,
              phone,
              createdAt,
              emailVerified
            } = account;

            return {
              onClick: (): void => {
                userEditHandler(id);
              },

              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    userEditHandler(id);
                  }
                },
                {
                  text: "Remove",
                  onClick: (): void => {
                    userDeleteHandler(id);
                  },
                  cls: "danger"
                }
              ],

              cells: [
                {
                  infoWithImage: {
                    title: fullName,
                    image
                  }
                },
                {
                  label: email
                },
                {
                  label: phone ? phone : "-"
                },
                {
                  label: dateConverter(new Date(createdAt || ""))
                },
                {
                  label: emailVerified ? "Active" : "In Active"
                }
              ]
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    accountsList: {
      type: Array as PropType<IUserAccount[]>,
      required: true
    },

    sort: {
      type: Object as PropType<operatorsTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    operatorHead(): tableHeadCells {
      const { byName, byDateCreated } = this.currentSort;

      return [
        {
          size: "fluid",
          label: "name",
          id: "user-name",
          sort: {
            keyName: byName.keyName,
            order: byName.order
          }
        },
        {
          size: "lg",
          label: "Email",
          id: "user-email"
        },
        {
          size: "md",
          label: "phone",
          id: "user-phone"
        },
        {
          size: "lg",
          label: "Date Created",
          id: "user-credit",
          sort: {
            keyName: byDateCreated.keyName,
            order: byDateCreated.order
          }
        },
        {
          size: "xs",
          label: "Status",
          id: "user-status"
        }
      ];
    }
  }
});
