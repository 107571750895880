import { ref } from "@vue/runtime-core";
import { Ref } from "vue";
import { useStore } from "vuex";
import useLocalizations from "@/hooks/localizations/useLocalizations";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";
import {
  IFetchAboutLocalizationData,
  IMappedAboutLocalization
} from "@/api/services/about-products/aboutTypes";
import { useBaseAppLayoutOptions } from "./useAboutProduct";

export default function useAboutLocalizations(
  options: useBaseAppLayoutOptions
) {
  const { selectedTab } = options;
  const store = useStore();
  const localizationList: Ref<IMappedAboutLocalization[]> = ref([]);
  const { localizations } = useLocalizations();

  const fetchAboutLocalization = async (aboutId: number) => {
    const { data } = await store.dispatch("fetchAboutLocalizations", {
      aboutId,
      type: selectedTab.value
    });
    return data;
  };

  const mapAbout = (
    aboutLocalization: IFetchAboutLocalizationData[],
    id: number
  ): IMappedAboutLocalization[] => {
    return localizations.value.map((localization: ILocalizations) => {
      const matchingLocalization = aboutLocalization?.find(
        (localizationItem: any) =>
          localizationItem.localization?.id === localization?.id
      );
      const title = matchingLocalization ? matchingLocalization.title : "";
      const description = matchingLocalization
        ? matchingLocalization.description
        : "";
      return {
        ...localization,
        id: matchingLocalization?.id || null,
        localizationId: localization?.id,
        title,
        description,
        aboutRecordId: id || -1
      };
    });
  };

  const startEditing = async (id: number) => {
    const aboutLocalization: IFetchAboutLocalizationData[] = await fetchAboutLocalization(
      id
    );

    localizationList.value = mapAbout(aboutLocalization, id);
  };

  const saveLocalization = async (
    localization: IMappedAboutLocalization
  ): Promise<boolean> => {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("editAboutLocalization", {
      data: localization,
      type: selectedTab.value
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  };

  return {
    localizations,
    localizationList,
    startEditing,
    saveLocalization
  };
}
