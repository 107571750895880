
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { IMappedAboutLocalization } from "@/api/services/about-products/aboutTypes";
import { optionsAbouts } from "@/hooks/about-product/useAboutProduct";

interface EditData {
  id: number;
  title?: string;
  description: string;
  type?: string;
}
export default defineComponent({
  name: "EditAboutDialog",
  components: {
    AppButton,
    AppInput,
    TabsDialog
  },
  inheritAttrs: false,
  props: {
    // disabled: { type: Boolean, default: true },
    editingData: {
      type: Object as PropType<EditData>,
      required: false
    },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "" },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    localizationsList: {
      type: Array as PropType<IMappedAboutLocalization[]>,
      default: () => []
    },
    selectedParentTab: {
      type: Object as PropType<optionsAbouts>,
      required: true
    }
  },
  emits: {
    submit: null,
    hide: null,
    selectMenuItem: null,
    saveLocalization: null
  },

  data() {
    return {
      editData: {} as EditData,
      avatar: "" as string | File,
      selectedBrand: 0 as number,
      valid: false as boolean,
      selectedTab: 0
    };
  },
  computed: {
    editModalTitle() {
      return this.title;
    },
    tabs() {
      const details = {
        title: "Details",
        id: 0
      };

      return [
        details,
        ...this.localizationsList.map((el: any) => ({
          title: el.short_name,
          id: el.localizationId
        }))
      ];
    },
    localizations() {
      return [...this.localizationsList];
    }
  },
  watch: {
    opened() {
      this.selectedTab = 0;
    },
    editingData(data) {
      if (data) {
        this.editData = { ...data };
      }
    },
    editData: {
      handler(data: EditData) {
        if (data?.title && data?.description !== "") {
          this.valid = true;
        }
      },
      deep: true
    }
  },
  methods: {
    saveLocalization(localization: IMappedAboutLocalization) {
      this.$emit("saveLocalization", localization);
    },
    submit() {
      this.$emit("submit", this.editData);
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    hide() {
      // this.editData = {} as EditData;
      this.$emit("hide");
    },
    addDevice() {
      if (this.valid) {
        this.$emit("submit", {
          ...this.editData
        });
      }
    }
  }
});
